import React, { useMemo, useState } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useQuery } from '@tanstack/react-query';
import Navbar from '../../../components/Navbar';
import Loaders from '../../../components/Loaders';
import { MaterialReactTable } from 'material-react-table';
import moment from 'moment/moment';
;

export default function CompanySolution() {
    const [archived, setArchived] = useState(false)
    const axiosPrivate = useAxiosPrivate();

    const handleCompanySolution = () => {
        const res = axiosPrivate.get(`/company-solution/all`)
        return res;
    };

    const handleArchiveSubmit = (id) => {
        const res = axiosPrivate.put(`/company-solution/archived/${id}`)
        res.then((res) => {
            if (res?.data?.success) {
                refetch()
            }
        })
        return res;
    };

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => (
                    <>
                        Name: {row?.name || 'N/A'} <br /> <br />
                        Phone Number: {row?.phone || 'N/A'} <br /> <br />
                        Email: {row?.email || 'N/A'} <br /> <br />
                        CVR Number: {row?.cvr_number || 'N/A'}
                    </>
                ), //alternate way


                header: "Customer Information",
                muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => {
                    return moment(row?.created_at).format('MMMM Do YYYY, h:mm a')
                }, //alternate way
                id: "created_at", //id required if you use accessorFn instead of accessorKey
                header: "Submited at",
                Header: <i style={{ color: "red" }}>Submited at</i> //optional custom markup
            },
            {
                accessorFn: (row) => `${row?.desciption ? row?.desciption : 'N/A'}`, //alternate way
                id: "desciption", //id required if you use accessorFn instead of accessorKey
                header: "Description",
                Header: <i style={{ color: "orange" }}>Description</i> //optional custom markup
            },
            {
                accessorFn: (row) => <div>
                    {
                        row.archived ?
                            <button onClick={() => handleArchiveSubmit(row.id)} className='btn btn-danger mx-2 text-white fs-6' data-toggle="tooltip" data-placement="bottom" title="Unarchived"><i class="fa-solid fa-box-open"></i></button>
                            :
                            <button onClick={() => handleArchiveSubmit(row.id)} className='btn btn-success mx-2 text-white fs-6' data-toggle="tooltip" data-placement="bottom" title="Archived"><i class="fa-solid fa-box"></i></button>
                    }

                </div>,
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "oranage" }}>Action</i> //optional custom markup


            }
        ],
        []
    );

    const { data: allCompanySolution = [], isLoading, refetch } = useQuery({
        queryKey: ['get-company-solution'],
        queryFn: handleCompanySolution,
        cacheTime: 5000,
    })

    const handleArchive = (value) => {
        setArchived(value)
    }

    return (
        <>
            <Navbar
                title="Company Solution"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }
            <div className='container my-5'>

                {allCompanySolution?.data && <>
                    <div className="d-flex justify-content-end">
                        {

                            archived ? <button className='btn btn-primary my-2' onClick={() => handleArchive(false)}> Show Unarchived List</button>
                                : <button className='btn btn-info text-white my-2' onClick={() => handleArchive(true)}> Show Archived List</button>
                        }
                    </div>
                    <MaterialReactTable
                        columns={columns}
                        data={allCompanySolution?.data?.data.filter(item => {
                            if (!archived) {
                                return !item?.archived
                            } else {
                                return item?.archived
                            }
                        })} />
                </>}
            </div>
        </>
    )
}
